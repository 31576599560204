<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { Ref, computed, onBeforeMount, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import Modal from '@/components/Modal.vue'
import { useModal } from '@/hooks/modal/useModal'

const { isOpen } = useModal()
const { width: windowWidth } = useWindowSize()
const MOBILE_BREAKPOINT = 768
const isMobile = computed(() => windowWidth.value <= MOBILE_BREAKPOINT)

/**
 * Convert the configured component names in the router to a set.
 */
const components = computed(() => {
  const route = useRoute()
  // flatmap out the names of the components needed.
  // pop them into a Set<> for uniqueness.
  return new Set(route.matched.flatMap((c) => (c?.components ? Object.keys(c.components) : [])))
})

onBeforeMount(() => {
  // read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue === 'dark' || window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  }
})

const getMarginLeftForMain = (hasLeftAside: boolean, isSidenavExpanded: boolean) => {
  if (isSidenavExpanded) return 'md:ml-64'
  if (!hasLeftAside) return 'ml-0'
  return 'md:ml-[4.125rem]'
}

// sidenav expand collapse
const isSidenavExpanded: Ref<boolean> = ref(false)
const toggleSidenav = (forceExpand?: boolean) => {
  isSidenavExpanded.value = forceExpand || !isSidenavExpanded.value
}
</script>
<template>
  <Modal v-show="isOpen">
    <div id="modal-container"></div>
  </Modal>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-50 max-h-full overflow-hidden">
    <!-- Header -->
    <header class="bg-white dark:bg-gray-800 fixed left-0 right-0 top-0 z-30 sm:z-40">
      <nav
        v-if="components.has('header')"
        id="page-header"
        class="px-4 py-2.5 border-b border-gray-200 dark:border-gray-700"
      >
        <RouterView name="header" :isSidenavExpanded="isSidenavExpanded" @toggle-sidenav="toggleSidenav()" />
      </nav>

      <RouterView name="navbar" v-if="components.has('navbar')" />
    </header>

    <!-- Sidebar -->
    <aside
      v-if="components.has('leftAside')"
      class="fixed overflow-hidden top-0 left-0 bottom-0 z-40 mt-[3.750rem] transition-all duration-300 bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
      :class="isSidenavExpanded ? 'w-64' : isMobile ? 'w-0' : 'w-[4.125rem]'"
      aria-label="Sidenav"
      id="drawer-navigation"
    >
      <RouterView name="leftAside" :isSidenavExpanded="isSidenavExpanded" @toggle-sidenav="toggleSidenav" />
    </aside>

    <!-- backdrop from sidenav on mobile -->
    <div
      v-if="isSidenavExpanded"
      tabindex="1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-30 w-full overflow-x-hidden overflow-y-auto inset-0 h-full backdrop-blur-[2px] p-2 sm:hidden"
      @click.stop="toggleSidenav(false)"
    ></div>

    <!-- Main section -->
    <main
      v-if="components.has('main')"
      id="main-content"
      class="min-h-screen relative flex flex-col mx-auto transition-all duration-300"
      :class="[
        components.has('navbar') ? 'pt-32 lg:pt-36' : { 'pt-[3.750rem]': components.has('header') },
        getMarginLeftForMain(components.has('leftAside'), isSidenavExpanded),
      ]"
    >
      <RouterView name="main" />
    </main>
  </div>
</template>
